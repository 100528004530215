@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,600&display=swap');

@font-face {
  font-family: 'SF Mono';
  src: url(./assets/fonts/SF-Mono/SFMono-Regular.otf) format('opentype'),
  url(./assets/fonts/SF-Mono/SFMono-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Andale Mono';
  src: url(./assets/fonts/Andale-Mono/andale-mono.ttf)
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  display: flex;
}

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}